import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to technology</title>
);

import Layout from "../../layouts/default";
export const TechnologyPage = ({
  data,
}: PageProps<Queries.TechnologyVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with sports videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to sports and fitness of various kinds. We
              have <Link to="/">lots of other videos</Link> if you&apos;re
              interested in other topics.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default TechnologyPage;

export const pageQuery = graphql`
  query TechnologyVideos {
    allVideosYaml(filter: { topics: { in: "technology" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
